import React from "react"
import { PrimaryTitle } from "../../elements/Titles"
import showcaseImg from "../../images/showcase-pics.jpg"
import styled from "styled-components"
import { Link } from "gatsby"
import { PrimaryButton } from "../../elements/Buttons"

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 1.9rem;
  }
`

const ServiceLi = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;

  ul {
    margin: 0 2rem;
    font-size: 1.1rem;
  }
`

function Showcase() {
  return (
    <StyledSection className="wrapper">
      <PrimaryTitle>
        Our time and resources are concentrated in building <br />
        exceptional new homes
      </PrimaryTitle>
      <img src={showcaseImg} alt="collage of alto and homes" />
      <PrimaryTitle>We are proud to provide:</PrimaryTitle>
      <ServiceLi>
        <ul>
          <li>Professionalism and attention to detail.</li>
          <li>
            Readily available work force. We have an experienced
            <br /> and reliable team excited to build your home.
          </li>
          <li>
            An understanding that building a custom home is a <br />
            significant investment of your time and money.
          </li>
          <li>
            Reasonably priced high-end craftsmanship.
            <br />
            Excellence is affordable.
          </li>
        </ul>
        <ul>
          <li>
            Transparent pricing & construction. Clients are provided <br /> with
            a price and materials list before the work begins.
          </li>
          <li>
            Clients can request various upgrades to include
            <br /> custom wine cellar, indoor bar, and outdoor grill/bar
          </li>
          <li>Responsiveness and care throughout the entire process.</li>
          <li>Quality new home construction is our priority and goal.</li>
        </ul>
      </ServiceLi>
      <PrimaryTitle>ready to build? call us today</PrimaryTitle>
      <Link to="contact">
        <PrimaryButton>Contact Us</PrimaryButton>
      </Link>
    </StyledSection>
  )
}

export default Showcase
