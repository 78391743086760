import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { PrimaryButton } from "../elements/Buttons"
import Showcase from "../components/build/Showcase"

function build() {
  return (
    <Layout>
      <SEO title="Building Exceptional New Homes" />

      <Showcase />
    </Layout>
  )
}

export default build
